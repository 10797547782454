@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body,
  #__next {
    @apply h-full;
  }
  body {
    @apply overflow-hidden bg-black text-base leading-[120%] tracking-[0.0125rem] text-white antialiased dark:text-white;
  }
  button,
  input,
  optgroup,
  select,
  textarea {
    @apply text-sm tracking-[0.0125rem];
  }
  .text-display {
    @apply text-3xl font-medium sm:text-6xl sm:tracking-[-0.125rem] md:text-7xl md:tracking-[-0.125rem];
  }
  h1 {
    @apply text-2xl font-medium sm:text-4xl sm:tracking-[-0.0625rem] md:text-5xl md:tracking-[-0.0625rem];
  }
  h2 {
    @apply text-xl font-medium sm:text-3xl sm:tracking-normal md:text-4xl md:tracking-[-0.0625rem];
  }
  h3 {
    @apply text-lg font-medium sm:text-2xl md:text-3xl;
  }
  h4 {
    @apply text-base font-medium sm:text-lg md:text-2xl;
  }
  h5 {
    @apply text-sm font-medium sm:text-base md:text-lg;
  }
  h6 {
    @apply text-xs font-medium sm:text-sm md:text-base;
  }
}
.text-5xl {
  @apply md:text-6xl;
}
.text-4xl {
  @apply md:text-5xl;
}
.text-3xl {
  @apply md:text-4xl;
}
.text-2xl {
  @apply md:text-3xl;
}
.text-xl {
  @apply md:text-2xl;
}
.text-lg {
  @apply md:text-xl;
}
.text-base {
  @apply md:text-lg;
}
.text-sm {
  @apply md:text-base;
}
.text-xs {
  @apply md:text-sm;
}

.custom-scrollbar::-webkit-scrollbar {
  @apply w-4;
}
.custom-scrollbar::-webkit-scrollbar-track {
  @apply bg-black;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  @apply rounded-full border-4 border-solid border-black bg-gray-500;
}
.scrollbar-gray-800::-webkit-scrollbar-track {
  @apply bg-gray-800;
}
.scrollbar-gray-800::-webkit-scrollbar-thumb {
  @apply border-gray-800;
}
.scrollbar-gray-700::-webkit-scrollbar-track {
  @apply bg-gray-700;
}
.scrollbar-gray-700::-webkit-scrollbar-thumb {
  @apply border-gray-700;
}

*,
*::before,
*::after {
  @apply underline-offset-4;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="search"]::-webkit-search-cancel-button {
  opacity: 0;
}

@import "./fonts.css";

.tiptap {
  @apply min-h-28 rounded-b-md bg-gray-700 p-4 text-base outline-none sm:text-base;

  > * + * {
    @apply mt-3.5;
  }

  a {
    @apply text-primary;
  }

  ul,
  ol {
    @apply px-8;
  }

  ul {
    @apply list-disc;
  }
  ol {
    @apply list-decimal;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply leading-tight;
  }

  pre {
    @apply rounded-sm bg-gray-900 px-4 py-2 font-mono;

    code {
      @apply bg-none p-0 text-xs sm:text-xs;
    }
  }

  blockquote {
    @apply border-l-2 border-white/50 pl-4;
  }
}
